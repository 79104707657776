<template>
  <div class="oznam" v-if="show || showModernization">
    <p v-if="show && !showModernization" class="info">
      Vážení zákazníci, predajňa z dôvodu začatia zimnej sezóny bude od {{dateToSkString(from)}} do {{dateToSkString(to)}} ZATVORENÁ.<br />
      V prípade záujmu si dohodnite stretnutie na telefónnom čísle <a href="callto:+421903137718">+421 903 137 718</a>.
    </p>
    <p v-if="showModernization" class="modernizationInfo">
      Vážení zákazníci, z dôvodu rekonštrukcie bude predajňa od <strong>{{dateToSkString(modernizationFrom)}}</strong> do <strong>{{dateToSkString(modernizationTo)}}</strong> ZATVORENÁ.
    </p>
  </div>
</template>

<style>
.oznam {
  background:#dc3545;
  color: white;
  line-height: 1;
  padding: 1rem 0;
}
.oznam a {
    color: white;
}
</style>

<script>
export default {
  data() {
    return {
      today: new Date(),
      from: new Date(2024, 11, 1),
      to: new Date(2025, 1, 26),
      modernizationFrom: new Date(2024, 9, 1),
      modernizationTo: new Date(2024, 10, 30),
    };
  },
  computed: {
      show : function () {
          return this.today < this.to;
      },
    showModernization : function () {
      return this.today < this.modernizationTo;
    }
  },
  methods: {
    dateToSkString(date) {
      if (date === undefined || date instanceof Date == false) return "";

      var options = { year: 'numeric', month: 'long', day: 'numeric' };

      return date.toLocaleDateString("sk-SK", options);
    }
  }
};
</script>

<style>
.oznam > .info {
  text-align: center;
  margin: 0;
  font-weight: bold;
  line-height: 2em;
}
.oznam > .modernizationInfo {
  text-align: center;
  margin: 0;
  //font-weight: bold;
  font-size: x-large;
  line-height: 2em;
}
</style>